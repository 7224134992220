import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import Breadcrumbs from "../../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import PublicProfileHero from "../../../components/PublicProfileHero"
import ImageCopy from "../../../components/ImageCopy"
import JobCard from "../../../components/AllJobPostingCard"

const PublicProfiles = ({ data, location, addressInfo, phoneInfo, linkedinInfo, facebookInfo, twitterInfo, contactUs, employeeInfo, totalEmployeeInfo, companyUrlFormat, companyWebsite, membershipName, perksTags, storiesInfo, jobPostingsInfo, companyDescription, publicProfile }) => {
  const profiles = data.post.companyProfiles
  const stories = data.story.nodes
  const jobs = data.job.nodes

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: profiles.companyName,
  })

  membershipName = profiles.membershipType
  const notBaseorEntry = membershipName !== "Entry" || membershipName !== "Base" || membershipName !== "Community (Entry)" || membershipName !== "Bronze (Base)"

  if (profiles.streetAddress) {
    addressInfo = (
      <li className="address">
        <i className="fas fa-map-marker-alt"></i>{" "}
        <span>
          {profiles.streetAddress}
          <br /> {profiles.city}, {profiles.state}
        </span>
      </li>
    )
  }
  if (profiles.phoneNumber) {
    phoneInfo = (
      <li className="phone">
        <i className="fas fa-phone-alt"></i> <span>{profiles.phoneNumber}</span>
      </li>
    )
  }
  if (notBaseorEntry) {
    if (profiles.linkedin) {
      linkedinInfo = (
        <li>
          <i className="fab fa-linkedin-in"></i>
          <a href={profiles.linkedin} className="link" target="_blank">
            @{profiles.companyName}
          </a>
        </li>
      )
    }
    if (profiles.facebook) {
      facebookInfo = (
        <li>
          <i className="fab fa-facebook-f"></i>
          <a href={profiles.facebook} className="link" target="_blank">
            @{profiles.companyName}
          </a>
        </li>
      )
    }
    if (profiles.twitter) {
      twitterInfo = (
        <li>
          <i className="fab fa-twitter"></i>
          <a href={profiles.twitter} className="link" target="_blank">
            @{profiles.companyName}
          </a>
        </li>
      )
    }
  }

  if (profiles.contactEmail) {
    contactUs = (
      <li>
        <a href={`mailto:${profiles.contactEmail}`} className="button button--primary">
          Contact Us
        </a>
      </li>
    )
  }

  if (profiles.companySize) {
    employeeInfo = (
      <li>
        <i className="fas fa-users"></i> <span>Employees {profiles.companySize}</span>
      </li>
    )
  }
  if (profiles.industry) {
    totalEmployeeInfo = (
      <li>
        <i className="fas fa-briefcase"></i> <span>Industry Type {profiles.industry}</span>
      </li>
    )
  }

  if (profiles.companyUrl) {
    companyUrlFormat = profiles.companyUrl.replace(/(^\w+:|^)\/\//, "")
    companyWebsite = (
      <li>
        <i className="fas fa-external-link-square-alt"></i>{" "}
        <a href={profiles.companyUrl} target="_blank">
          {companyUrlFormat}
        </a>
      </li>
    )
  }

  if (profiles.companyDescription) {
    companyDescription = (
      <>
        <h2>Company Description</h2>
        <p dangerouslySetInnerHTML={{ __html: profiles.companyDescription }} />
      </>
    )
  } else {
    companyDescription = (
      <>
        <h2>Company Description</h2>
        <p>More information about this company will be updated soon.</p>
      </>
    )
  }

  let perks = JSON.parse(profiles.perksBenefits)

  if (perks && perks.length > 0) {
    perksTags = (
      <div className="tags--wrapper">
        <h2>Company Benefits + Perks</h2>
        <div className="tags--container">
          {perks?.map((item, index) => {
            return (
              <span className="tag tag--blue tag--blue--perks" key={index}>
                {item.value}
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  let memberStackId = profiles.memberstackId
  let story = []

  for (let item in stories) {
    stories.sort((p1, p2) => p1.modified < p2.modified)
    if (stories[item].title === memberStackId) {
      const featuredStory = stories[item].featuredStories

      story.push({
        buttonUrl: "",
        linkUrl: "",
        target: "",
        featured: "",
        altText: "",
        image: {
          sourceUrl: featuredStory.storyImage,
        },
        title: featuredStory.storyHeader,
        content: featuredStory.storyDescription,
      })
      storiesInfo = <ImageCopy cards={story} storyCopy={true} />
    }
  }

  let getJobs = []
  for (let item in jobs) {
    if (jobs[item].jobPostings.memberstackId === memberStackId) {
      getJobs.push(jobs[item])
      jobPostingsInfo = (
        <>
          <div>
            <h2>Job Openings</h2>
          </div>
          {getJobs.map((job, index) => (
            <>
              <JobCard key={index} posting={job} />
            </>
          ))}
        </>
      )
    }
  }

  return (
    <Layout>
      <Seo title="Company Profile" />
      <Breadcrumbs crumbs={crumbs} />
      <PublicProfileHero profile={profiles} />

      <div className="profiles container">
        <div className="row profile__layout">
          <div className="sidebar">
            <ul>
              {addressInfo}
              {phoneInfo}
              {linkedinInfo}
              {facebookInfo}
              {twitterInfo}
              {contactUs}
            </ul>
          </div>

          <div className={`content ${membershipName === "Entry" || membershipName === "Community (Entry)" ? "no-hero" : ""}`}>
            <div className="content__header">
              <h1>{profiles.companyName}</h1>
              <ul>
                {employeeInfo}
                {totalEmployeeInfo}
                {companyWebsite}
              </ul>
            </div>
            <div className="content__description">
              {companyDescription}
              {storiesInfo}
              {perksTags}
              {jobPostingsInfo}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PublicProfiles

export const pageQuery = graphql`
  query ($id: String!) {
    post: wpCompanyProfile(id: { eq: $id }) {
      id
      title
      slug
      companyProfiles {
        city
        companyDescription
        companyName
        companyUrl
        companySize
        contactEmail
        fieldGroupName
        industry
        perksBenefits
        phoneNumber
        state
        streetAddress
        zip
        backgroundColor
        logoBackgroundColor
        logo
        logoAlt
        backgroundImage
        backgroundImageAlt
        memberstackId
        membershipType
        linkedin
        facebook
        twitter
      }
    }
    story: allWpFeaturedStory {
      nodes {
        id
        title
        modified
        featuredStories {
          storyDescription
          storyHeader
          starttime
          endtime
          storyImage
        }
      }
    }
    job: allWpJobPost {
      nodes {
        jobPostings {
          companyCity
          companyName
          companyState
          companyUrl
          endtime
          expirationDate
          featured
          industry
          jobDescription
          jobTitle
          memberstackId
          roleCategory
          starttime
          techStack
          typeOfPosition
          url
        }
      }
    }
  }
`
