import React from "react"
import {CATEGORIES} from "../constants/categories";
import {INDUSTRIES} from "../constants/industries";

//todo consolidate into a single map with the job-postings page
const jobCategories = CATEGORIES;

const positionTypes = INDUSTRIES;

const categoryBadgeFactory = (categories) => badgeFactory(categories, jobCategories)
const industryBadgeFactory = (categories) => badgeFactory(categories, positionTypes)

const badgeFactory = (categories, categoryMap) => {
  return categories?.map((c) => {
    if (categoryMap[c]) {
      const { categoryClass, displayName } = categoryMap[c]
      return (
        <span key={displayName} className={categoryClass}>
          {displayName}
        </span>
      )
    }
  })
}

const JobPostingCard = ({ posting, companyInfo, jobTypeInfo, getIndustry, postedDate }) => {

  if (posting.jobPostings.companyCity && posting.jobPostings.companyState) {
    companyInfo = (
      <h5>
        <i className="fas fa-map-marker-alt" /> {posting.jobPostings.companyCity}, {posting.jobPostings.companyState}
      </h5>
    )
  }
  if (posting.jobPostings.typeOfPosition) {
    jobTypeInfo = (
      <h5>
        <i className="fas fa-calendar-alt" /> {posting.jobPostings.typeOfPosition}
      </h5>
    )
  }

  getIndustry = []

  for (let item in posting) {
    if (posting[item].industry) {
      getIndustry.push(posting[item].industry)
    }
  }

  let roleCategory
  if (typeof posting.jobPostings.roleCategory === "object") {
    roleCategory = posting.jobPostings.roleCategory
  } else {
    roleCategory = JSON.parse(posting.jobPostings.roleCategory)
  }

  if (posting.modified) {
    postedDate = (
      <div className="job-card__left__date">
        <span>Posted {new Date(posting.modified).toDateString()}</span>
      </div>
    )
  }

  return (
    <div className="card job-card job-card--featured">
      <div className="job-card__left">
        <div className="job-card__left__heading">
          <h3>{posting.jobPostings.jobTitle}</h3>
        </div>
        <p>{posting.jobPostings.jobDescription}</p>
        <div className="job-card__left__tags">
          {posting.jobPostings.featured && <span className="tag tag--gold tag--gold--featured">Featured</span>}
          {posting.jobPostings.industry && industryBadgeFactory(getIndustry.map((ind) => ind))}
          {categoryBadgeFactory(roleCategory?.map((rc) => rc.label))}
        </div>
        {postedDate}
      </div>
      <div className="job-card__right">
        <div className="job-card__right__details">
          <h5>
            <i className="fas fa-building" /> {posting.jobPostings.companyName}
          </h5>
          {companyInfo}
          {jobTypeInfo}
        </div>
        <div className="job-card__right__cta">
          <a className="button button--external button--primary" href={posting.jobPostings.url} target="_blank">
            Apply For Jobs
          </a>
          { posting.jobPostings.companyName && 
            <a className="link" href={`/general/company-profile/${posting.jobPostings.companyName.replace(/\s+/g, "-").toLowerCase()}`}>
            View Company Profile
          </a>
          }
          
        </div>
      </div>
    </div>
  )
}

export default JobPostingCard
