import React from "react"
import ImageDefault from "../assets/images/default-hero-bg.jpg"
import logoDefault from "../assets/images/company-profile-placeholder.png"
import ImageCandidate from "../assets/images/default-hero-bg.jpg"
import logoCandidate from "../assets/images/candidate-profile-placeholder.png"

const ProfileHero = ({ profile, backgroundImage, logoImage, membershipName }) => {
  const logoBgColor = {
    backgroundColor: profile.logoBackgroundColor,
  }

  const imageBgColor = {
    backgroundColor: profile.backgroundColor,
  }

  if (profile) {
    if (profile.logo) {
      logoImage = <img src={profile.logo} alt={profile.companyName} />
    } else {
      logoImage = <img src={logoDefault} alt={profile["company-name"]} className="default-logo" />
    }

    if (profile.backgroundImage) {
      backgroundImage = <img src={profile.backgroundImage} alt={profile.companyName} />
    } else {
      backgroundImage = <img src={ImageDefault} alt={profile["company-name"]} />
    }
  }

  membershipName = profile.membershipType

  return (
    <div className={`profile-hero ${membershipName === "Entry" || membershipName === "Community (Entry)" ? "entry--backgroundImage" : ""}`}>
      <div className="row">
        <div className="profile-hero--banner" style={imageBgColor}>
          {backgroundImage}
        </div>
        <div className="profile-hero--logo" style={logoBgColor}>
          {logoImage}
        </div>
      </div>
    </div>
  )
}

export default ProfileHero
